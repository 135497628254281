<template>
  <div>
    <v-container fluid class="py-6">
        <v-row>
            <v-col cols="1">
                <v-dialog
                    v-model="filtersDialog"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Filters
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Filters</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field
                                            v-model="search"
                                            label="Search"
                                            outlined
                                            dense
                                            height="43"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            v-model="wherePriceAN"
                                            label="Price AN"
                                            hint="Example: 10-50"
                                            outlined
                                            dense
                                            height="43"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            v-model="whereDR"
                                            label="DR filter"
                                            hint="Example: 40-100"
                                            outlined
                                            dense
                                            height="43"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                    <v-menu
                                        ref="showFilterCollaborateAtRange"
                                        v-model="showFilterCollaborateAtRange"
                                        :close-on-content-click="false"
                                        :return-value.sync="collaborateAtRange"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="collaborateAtRange"
                                                label="Collaborate at"
                                                readonly
                                                outlined
                                                dense
                                                height="43"
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="collaborateAtRange"
                                            range
                                            no-title
                                            dark
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="showFilterCollaborateAtRange = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="resetCollaborateAtRangeFilter()"
                                            >
                                                Reset
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="setCollaborateAtRangeFilter()"
                                            >
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                    <v-col cols="4">
                                        <div class="d-flex ms-auto">
                                            <v-select
                                                v-model="filterStatuses"
                                                :items="statuses"
                                                multiple
                                                outlined
                                                dense
                                                height="43"
                                                label="Filter by status"
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <v-chip v-if="index == 0">
                                                        <span>{{ item }}</span>
                                                    </v-chip>
                                                    <span
                                                        v-if="index === 1"
                                                        class="grey--text text-caption"
                                                    >
                        (+{{ filterStatuses.length - 1 }})
                    </span>
                                                </template>
                                            </v-select>
                                        </div>
                                    </v-col>
                                    <v-col cols="4">
                                        <div class="d-flex ms-auto">
                                            <v-select
                                                v-model="filterLinkbuilders"
                                                :items="linkbuilders"
                                                :item-text="'name'"
                                                :item-value="'id'"
                                                multiple
                                                outlined
                                                dense
                                                height="43"
                                                label="Filter by linkbuilders"
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <v-chip v-if="index == 0">
                                                        <span>{{ item.name }}</span>
                                                    </v-chip>
                                                    <span
                                                        v-if="index === 1"
                                                        class="grey--text text-caption"
                                                    >
                        (+{{ filterLinkbuilders.length - 1 }})
                    </span>
                                                </template>
                                            </v-select>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <div class="d-flex ms-auto">
                                            <v-select
                                                v-model="filterProjects"
                                                :items="projects"
                                                :item-text="'name'"
                                                :item-value="'id'"
                                                multiple
                                                outlined
                                                dense
                                                height="43"
                                                label="Filter by projects"
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <v-chip v-if="index == 0">
                                                        <span>{{ item.name }}</span>
                                                    </v-chip>
                                                    <span
                                                        v-if="index === 1"
                                                        class="grey--text text-caption"
                                                    >
                        (+{{ filterProjects.length - 1 }})
                    </span>
                                                </template>
                                            </v-select>
                                        </div>
                                    </v-col>
                                    <v-col cols="4">
                                        <div class="d-flex ms-auto">
                                            <v-select
                                                v-model="filterNiches"
                                                :items="niches"
                                                :item-text="'name'"
                                                :item-value="'id'"
                                                multiple
                                                outlined
                                                dense
                                                height="43"
                                                label="Filter by niches"
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <v-chip v-if="index == 0">
                                                        <span>{{ item.name }}</span>
                                                    </v-chip>
                                                    <span
                                                        v-if="index === 1"
                                                        class="grey--text text-caption"
                                                    >
                                (+{{ filterNiches.length - 1 }})
                            </span>
                                                </template>
                                            </v-select>
                                        </div>
                                    </v-col>
                                    <v-col cols="4">
                                        <div class="d-flex ms-auto">
                                            <v-select
                                                v-model="filterCompetitors"
                                                :items="competitors"
                                                :item-text="'domain'"
                                                :item-value="'id'"
                                                multiple
                                                outlined
                                                dense
                                                height="43"
                                                label="Filter by competitors"
                                            >
                                                <template v-slot:selection="{ item, index }">
                                                    <v-chip v-if="index == 0">
                                                        <span>{{ item.domain }}</span>
                                                    </v-chip>
                                                    <span
                                                        v-if="index === 1"
                                                        class="grey--text text-caption"
                                                    >
                                (+{{ filterCompetitors.length - 1 }})
                            </span>
                                                </template>
                                            </v-select>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <v-checkbox
                                            v-model="withTrashed"
                                            label="With trashed"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="hideFiltersDialog()"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="resetFilters()"
                            >
                                Reset
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="applyFilters()"
                            >
                                Apply
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-col cols="3">
                <v-select
                    v-model="selectedHeaders"
                    :items="headers"
                    @change="changeSelectedHeaders($event)"
                    multiple
                    outlined
                    dense
                    height="43"
                    label="Show columns"
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index <= 0">
                            <span>{{ item.text }}</span>
                        </v-chip>
                        <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                        >
                        (...)
                    </span>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="1">
                <v-btn
                    elevation="0"
                    height="43"
                    class="
              font-weight-bold
              text-uppercase
              btn-default btn-outline-default
              shadow-none
              py-2
              me-2
            "
                    color="transparent"
                    small
                    @click="exportCsv()"
                >
                    <v-icon>ni ni-archive-2 me-1</v-icon>
                    CSV
                </v-btn>
            </v-col>
            <v-col cols="2">
                <v-dialog
                    v-model="bulkOperationsDialog"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Bulk operations
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Bulk operations</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="3">
                                        <div class="d-flex ms-auto">
                                            <v-select
                                                v-model="bulkOperations.status"
                                                :items="statuses"
                                                outlined
                                                dense
                                                height="43"
                                                label="Set status"
                                            ></v-select>
                                        </div>
                                    </v-col>
                                    <v-col cols="3">
                                        <div class="d-flex ms-auto">
                                            <v-select
                                                v-model="bulkOperations.linkbuilder"
                                                :items="linkbuilders"
                                                item-text="name"
                                                item-value="id"
                                                outlined
                                                dense
                                                height="43"
                                                label="Set linkbuilder"
                                            ></v-select>
                                        </div>
                                    </v-col>
                                    <v-col cols="3">
                                        <div class="d-flex ms-auto">
                                            <v-select
                                                v-model="bulkOperations.niches"
                                                :items="niches"
                                                item-text="name"
                                                item-value="id"
                                                multiple
                                                outlined
                                                dense
                                                height="43"
                                                label="Set niches"
                                            ></v-select>
                                        </div>
                                    </v-col>
                                    <v-col cols="3">
                                        <div class="d-flex ms-auto">
                                            <v-select
                                                v-model="bulkOperations.projects"
                                                :items="projects"
                                                item-text="name"
                                                item-value="id"
                                                multiple
                                                outlined
                                                dense
                                                height="43"
                                                label="Set projects"
                                            ></v-select>
                                        </div>
                                    </v-col>
                                    <v-col cols="3">
                                        <div class="d-flex ms-auto">
                                            <v-select
                                                v-model="bulkOperations.used_emails"
                                                :items="ourContacts"
                                                item-text="email"
                                                item-value="id"
                                                multiple
                                                outlined
                                                dense
                                                height="43"
                                                label="Set used emails"
                                            ></v-select>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="hideBulkOperationsDialog()"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="resetBulkOperations()"
                            >
                                Reset
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="applyBulkOperations()"
                            >
                                Apply
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-col cols="1">
                <v-btn
                    elevation="0"
                    height="43"
                    class="
              font-weight-bold
              text-uppercase
              btn-default btn-outline-default
              shadow-none
              py-2
              me-2
            "
                    color="transparent"
                    small
                    @click="deleteSelected()"
                >
                    <v-icon>ni ni-archive-2 me-1</v-icon>
                    Delete
                </v-btn>
            </v-col>
            <v-col cols="1">
                <v-dialog
                    v-model="importDonorsDialog"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Import
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Import donors from CSV file</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        You can download an example file <a href="/files/example-import.csv">here</a>
                                        <div class="d-flex ms-auto">
                                            <v-file-input
                                                accept=".csv"
                                                label="Select CSV file"
                                                small-chips
                                                show-size
                                                clearable
                                                v-model="importFile"
                                            ></v-file-input>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="hideImportDonorsDialog()"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="importDonors()"
                            >
                                Import
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
      <v-row class="my-5">
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
              <v-data-table
                  v-model="selectedRecords"
                  :headers="visibleHeaders"
                  :items="donors"
                  :options.sync="options"
                  :server-items-length="totalDonors"
                  :loading="loading"
                  class="table border-radius-xl elevation-1"
                  show-select
                  show-expand
                  fixed-header
                  height="750"
                  :footer-props="{
                    'items-per-page-options': [10, 25, 50, 100]
                  }"
              >
                  <template v-slot:top="{ pagination, options, updateOptions }">
                      <v-data-footer
                          :pagination="pagination"
                          :options="options"
                          @update:options="updateOptions"
                          :items-per-page-options="[10, 25, 50, 100]"
                      />
                  </template>

                  <template v-slot:item.domain="{ item }">
                      <v-btn
                          :href="'https://'+item.domain"
                          target="_blank"
                          elevation="2"
                          x-small
                      >
                          <v-icon left x-small>
                              fas fa-link
                          </v-icon>
                          {{ item.domain }}
                      </v-btn>
                  </template>

                  <template v-slot:item.status="{ item }">
                      <div class="">
                          <v-btn
                              @click="callModalToEditStatusField(item)"
                              elevation="0"
                              small
                              :ripple="false"
                              height="21"
                              outlined
                              class="
                                  border-radius-md
                                  font-weight-bolder
                                  px-3
                                  py-3
                                  badge-font-size
                                  ms-auto
                                "
                              :color="evaluateStatusColor(item.status)"
                          >
                              <v-icon size="16">
                                  {{ evaluateStatusIcon(item.status) }}
                              </v-icon>
                              &nbsp;{{ item.status }}
                          </v-btn>
                      </div>
                  </template>

                  <template v-slot:item.ahrefs_overview="{ item }">
                      <template v-if="item.ahrefs_overview">
                          <v-btn
                              :href="item.ahrefs_overview"
                              target="_blank"
                              elevation="2"
                              x-small
                          >
                              <v-icon left x-small>
                                  fas fa-link
                              </v-icon>
                              link
                          </v-btn>
                      </template>
                  </template>

                  <template v-slot:item.ahrefs_overview_v2="{ item }">
                      <template v-if="item.ahrefs_overview_v2">
                          <v-btn
                              :href="item.ahrefs_overview_v2"
                              target="_blank"
                              elevation="2"
                              x-small
                          >
                              <v-icon left x-small>
                                  fas fa-link
                              </v-icon>
                              link
                          </v-btn>
                      </template>
                  </template>

                  <template v-slot:item.price_bn="{ item }">
                      <template v-if="item.price_bn">
                          {{item.price_bn}}$
                      </template>
                  </template>

                  <template v-slot:item.price_an="{ item }">
                      <template v-if="item.price_an">
                          {{item.price_an}}$
                      </template>
                  </template>

                  <template v-slot:item.example_of_published_article="{ item }">
                      <template v-if="item.example_of_published_article">
                          <v-btn
                              :href="item.example_of_published_article"
                              target="_blank"
                              elevation="2"
                              x-small
                          >
                              <v-icon left x-small>
                                  fas fa-link
                              </v-icon>
                              link
                          </v-btn>
                      </template>
                  </template>

                  <template v-slot:item.target_url="{ item }">
                      <template v-if="item.competitors.length > 0">
                          <v-btn
                              :href="item.competitors[0].pivot.referring_page_url"
                              target="_blank"
                              elevation="2"
                              x-small
                          >
                              <v-icon left x-small>
                                  fas fa-link
                              </v-icon>
                              link
                          </v-btn>
                      </template>
                  </template>

                  <template v-slot:item.contacts="{ item }">
                      <template v-if="item.contacts && item.contacts.length > 0">
                          <div v-for="contact in item.contacts">
                              <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                          class="ma-2"
                                          small
                                          outlined
                                          :color="evaluateContactColor(contact)"
                                          @click="putContactIntoClipboard(contact)"
                                          v-bind="attrs"
                                          v-on="on"
                                      >
                                          <v-icon left x-small>
                                              {{ evaluateContactIcon(contact) }}
                                          </v-icon>
                                          {{ contact.first_name }}
                                          <span v-if="contact.position">&nbsp;({{ contact.position }})</span>
                                          <template v-if="contact.notes">
                                              <v-dialog
                                                  v-model="dialog"
                                                  width="600px"
                                              >
                                                  <template v-slot:activator="{ on: on2, attrs: attrs2 }">
                                                      <v-icon right
                                                              x-small
                                                              v-bind="attrs2"
                                                              v-on="on2"
                                                      >
                                                          far fa-clipboard
                                                      </v-icon>
                                                  </template>
                                                  <v-card>
                                                      <v-card-title>
                                                          <span class="text-h5">Notes:</span>
                                                      </v-card-title>
                                                      <v-card-text>
                                                          <v-textarea
                                                              v-model="contact.notes"
                                                          >
                                                          </v-textarea>
                                                      </v-card-text>
                                                  </v-card>
                                              </v-dialog>
                                          </template>
                                      </v-btn>
                                  </template>
                                  <span>Click to copy: {{ contact.value }}</span>
                              </v-tooltip>
                          </div>
                      </template>
                      <v-btn
                          @click="callModalCreateContact(item)"
                          elevation="2"
                          icon
                          x-small
                      >
                          <v-icon>
                              fas fa-plus
                          </v-icon>
                      </v-btn>
                  </template>

                  <template v-slot:item.used_email="{ item }">
                      <div style="min-width: 100px">
                          <v-combobox
                              v-model="item.used_email"
                              :items="ourContacts"
                              item-text="email"
                              item-value="id"
                              label="Our contacts"
                              small-chips
                              multiple
                              dense
                              solo
                              @change="attachOurContacts(item)"
                          ></v-combobox>
                      </div>
                  </template>

                  <template v-slot:item.domain_traffic="{ item }">
                      <template v-if="item.domain_traffic >= 1000 && item.domain_traffic < 1000000">
                          {{ Math.round(item.domain_traffic/1000) }}K
                      </template>
                      <template v-else-if="item.domain_traffic >=1000000">
                          {{ Math.round(item.domain_traffic/1000000) }}M
                      </template>
                      <template v-else>
                          {{ item.domain_traffic }}
                      </template>
                  </template>

                  <template v-slot:item.linkbuilder="{ item }">
                      <div class="">
                          <v-btn
                              @click="callModalToEditLinkbuilder(item)"
                              elevation="0"
                              small
                              :ripple="false"
                              height="21"
                              outlined
                              class="
                                  border-radius-md
                                  font-weight-bolder
                                  px-3
                                  py-3
                                  badge-font-size
                                  ms-auto
                                "
                          >
                              <span v-if="item.linkbuilder && item.linkbuilder.name">
                                  &nbsp;{{ item.linkbuilder.name  }}
                              </span>
                          </v-btn>
                      </div>
<!--                      <template v-if="item.linkbuilder && item.linkbuilder.name">-->
<!--                          <v-btn-->
<!--                              :href="'/users/'+item.linkbuilder.id"-->
<!--                              target="_blank"-->
<!--                              elevation="2"-->
<!--                              x-small-->
<!--                          >-->
<!--                              <v-icon left x-small>-->
<!--                                  fas fa-link-->
<!--                              </v-icon>-->
<!--                              {{ item.linkbuilder.name }}-->
<!--                          </v-btn>-->
<!--                      </template>-->
                  </template>

                  <template v-slot:item.projects="{ item }">
                      <div style="min-width: 100px">
                          <v-combobox
                              v-model="item.projects"
                              :items="projects"
                              item-text="name"
                              item-value="id"
                              label="Projects"
                              small-chips
                              multiple
                              dense
                              solo
                              @change="attachProjects(item)"
                          ></v-combobox>
                      </div>
                  </template>

                  <template v-slot:item.niches="{ item }">
                      <div style="min-width: 100px">
                          <v-combobox
                              v-model="item.niches"
                              :items="niches"
                              item-text="name"
                              item-value="id"
                              label="Niches"
                              small-chips
                              multiple
                              dense
                              solo
                              @change="attachNiches(item)"
                          ></v-combobox>
                      </div>
                  </template>

                  <template v-slot:item.notes="{ item }">
                      <v-btn
                          :color="(item.notes && item.notes.length > 0 ) ? 'green' : 'gray'"
                          @click="callModalEditNotes(item)"
                          outlined
                      >
                          <template v-if="(item.notes && item.notes.length > 0 )">
                              <v-icon x-small>
                                  far fa-clipboard
                              </v-icon>
                          </template>
                      </v-btn>
                  </template>

                  <template v-slot:item.added_at="{ item }">
                      {{ prepareDateOfAnalyse(item) }}
                  </template>

                  <template v-slot:item.latest_collaboration_date="{ item }">
                      <template v-if="item.latest_collaboration_date">
                          {{ item.latest_collaboration_date.split(' ')[0] }}
                      </template>
                  </template>

                  <template v-slot:item.spammy_niches_presence="{ item }">
                      <v-btn
                          :href="'https://www.google.com/search?q=site%3Ahttps%3A%2F%2F'+item.domain+'%2F+intitle%3Aessay+OR+casino'"
                          target="_blank"
                          elevation="2"
                          x-small
                      >
                          <v-icon left x-small>
                              fas fa-link
                          </v-icon>
                          link
                      </v-btn>
                  </template>

                  <template v-slot:item.actions="{ item }">
                      <v-btn
                          @click="editItem(item)"
                          icon
                          elevation="0"
                          :ripple="false"
                          height="28"
                          min-width="36"
                          width="36"
                          class="btn-ls me-2 my-2 rounded-sm"
                          color="#67748e"
                      >
                          <v-icon size="14" class="material-icons-round">
                              fas fa-edit
                          </v-icon>
                      </v-btn>
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                          <extended-record :donorRecord="item"></extended-record>
                      </td>
                  </template>
              </v-data-table>
<!--            <table-orders></table-orders>-->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import TableOrders from "./Widgets/TableOrders";
// import TableOrders from '@/views/Ecommerce/Orders/Widgets/TableOrders';
import ExtendedRecord from './components/ExtendedRecord'
import axiosIns from '../../../plugins/axios';

export default {
  name: "DonorList",
  components: {
    ExtendedRecord,
  },
  data: () => {
      return {
          withTrashed: false,
          selectedRecords: [],
          bulkOperations: {
              linkbuilder: null,
              status: null,
              niches: null, // could be an array
              projects: null, // could be an array
              used_emails: null, // could be an array
              remove: false,
          },
          search: '',
          collaborateAtRange: [],
          showFilterCollaborateAtRange: false,
          wherePriceAN: null,
          whereDR: null,
          donors: [],
          totalDonors: 10,
          loading: false,
          options: {},
          selectedHeaders: [],
          filtersDialog: false,
          bulkOperationsDialog: false,
          importDonorsDialog: false,
          importFile: null,
          headers: [
              {
                  text: "Domain",
                  value: "domain",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Status",
                  value: "status",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Project",
                  value: "projects",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "DR",
                  value: "domain_rank",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Traffic",
                  value: "domain_traffic",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Added On",
                  value: "added_at",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Ahrefs overview",
                  value: "ahrefs_overview",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Ahrefs overview (v2)",
                  value: "ahrefs_overview_v2",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Price BN",
                  value: "price_bn",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Price AN",
                  value: "price_an",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Niches",
                  value: "niches",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Contacts",
                  value: "contacts",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Example of article",
                  value: "example_of_published_article",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Target URL",
                  value: "target_url",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Latest collaboration date",
                  value: "latest_collaboration_date",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Used email",
                  value: "used_email",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Linkbuilder",
                  value: "linkbuilder",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Notes",
                  value: "notes",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Spammy Niches Presence",
                  value: "spammy_niches_presence",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              // { text: '', value: 'data-table-expand' },
              {
                  text: "Actions",
                  value: "actions",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
          ],
          filterStatuses: [],
          statuses: [
              'New', 'Processing', 'Expensive', 'Outreach later',
              'Filtered out', 'Didn\'t respond', 'Successful collaboration'
          ],
          filterLinkbuilders: [],
          filterProjects: [],
          projects: [],
          filterNiches: [],
          niches: [],
          ourContacts: [],
          filterCompetitors: [],
          competitors: [],
      };
  },
    watch: {
        options: {
            async handler () {
                await this.loadDonors()
            },
            deep: true,
        },
    },
  computed: {
      visibleHeaders() {
          return this.headers.filter((item) => {
              return this.selectedHeaders.includes(item.value);
          });
      },
      collaborateAtRangeText() {
          return this.collaborateAtRange.join(' ~ ');
      },
      linkbuilders() {
          return this.$store.getters['other/linkbuilders'];
      },
  },
  beforeMount () {
      // this.loadDonors();
  },
  mounted () {
      setTimeout(() => {
          this.selectedHeaders = this.$store.getters['user/profile']?.settings?.donors?.selectedHeaders
              || [
                  'domain', 'status', 'projects', 'domain_rank', 'domain_traffic', 'added_at', 'ahrefs_overview', 'ahrefs_overview_v2',
                  'contacts', 'linkbuilder', 'niches', 'latest_collaboration_date', 'example_of_published_article', 'target_url',
                  'price_bn', 'price_an', 'used_email', 'notes', 'spammy_niches_presence', 'actions',
              ];
      }, 2000);
      this.loadCompetitors();
      this.loadProjects();
      this.loadNiches();
      this.loadOurContacts();
  },
    methods: {
        prepareDateOfAnalyse(item) {
            let date = item.added_at.split(' ')[0];
            let dateItems = date.split('-');
            return dateItems[1] + '/' + dateItems[2] + '/' + dateItems[0]; // mm/dd/yyyy
        },
        attachOurContacts(item) {
            axiosIns.post(`/api/donor-domains/${item.id}/sync-used-emails`, {
                usedEmail: item.used_email
            })
                .then((response) => {
                    this.ourContacts = response.data.ourContacts;
                    item = response.data.donor;
                })
                .catch((error) => {
                    this.$store.commit('app/SNACKBAR', {
                        show: true,
                        body: error.response.data.message,
                        type: 'error',
                    });
                });
        },
        attachProjects(item) {
            axiosIns.post(`/api/donor-domains/${item.id}/sync-projects`, {
                projects: item.projects
            })
                .then((response) => {
                    this.projects = response.data.projects;
                    item = response.data.donor;
                })
                .catch((error) => {
                    this.$store.commit('app/SNACKBAR', {
                        show: true,
                        body: error.response.data.message,
                        type: 'error',
                    });
                });
        },
        attachNiches(item) {
            console.log(item);
            axiosIns.post(`/api/donor-domains/${item.id}/sync-niches`, {
                niches: item.niches
            })
                .then((response) => {
                    this.niches = response.data.niches;
                    item = response.data.donor;
                })
                .catch((error) => {
                    this.$store.commit('app/SNACKBAR', {
                        show: true,
                        body: error.response.data.message,
                        type: 'error',
                    });
                });
        },
        callModalToEditLinkbuilder(donorRecord) {
            let self = this;
            this.$store.commit('app/SHOW_EDIT_MODAL', {
                show: true,
                title: 'Edit linkbuilder',
                type: 'selectSingle',
                options: {
                    items: this.linkbuilders,
                    text: 'name',
                    value: 'id'
                },
                field: {
                    entityType: 'DonorDomain',
                    entityId: donorRecord.id,
                    type: 'string',
                    field: 'linkbuilder_user_id',
                    value: donorRecord.linkbuilder,
                },
                onSuccess: (entity) => {
                    console.log('saved');
                    console.log(entity);
                    console.log(entity.linkbuilder);
                    for (const item of self.linkbuilders) {
                        if (item.id === entity.linkbuilder_user_id) {
                            donorRecord.linkbuilder = item;
                        }
                    }
                    donorRecord.linkbuilder_user_id = entity['linkbuilder_user_id'];
                },
            });
        },
        callModalToEditStatusField(donorRecord) {
            let items = [
                'New', 'Processing', 'Expensive', 'Didn\'t respond',
                'Successful collaboration', 'Outreach later', 'Filtered out'
            ];
            this.$store.commit('app/SHOW_EDIT_MODAL', {
                show: true,
                title: 'Edit status',
                type: 'selectSingle',
                options: {
                    items: items,
                    text: '',
                    value: ''
                },
                field: {
                    entityType: 'DonorDomain',
                    entityId: donorRecord.id,
                    type: 'string',
                    field: 'status',
                    value: donorRecord.status,
                },
                onSuccess: (entity) => {
                    donorRecord.status = entity['status'];
                },
            });
        },
        async deleteSelected() {
            if (confirm('Do you really want to delete it?')) {
                const donorIds = this.selectedRecords.map((item) => {
                    return item.id;
                });
                this.$store.commit('app/SHOW_LOADING');
                await axiosIns.post(`/api/custom/delete-donors`, { donorIds });
                this.$store.commit('app/HIDE_LOADING');
                window.location.reload();
            }
        },
        async applyBulkOperations() {
            const donorIds = this.selectedRecords.map((item) => {
                return item.id;
            });
            this.bulkOperationsDialog = false;
            this.$store.commit('app/SHOW_LOADING');
            const url = '/api/custom/bulk-operations';
            await axiosIns.post(url, {
                donorIds,
                values: this.bulkOperations,
            });
            await this.loadDonors();
        },
        hideBulkOperationsDialog() {
            this.bulkOperationsDialog = false;
        },
        hideImportDonorsDialog() {
            this.importDonorsDialog = false;
        },
        resetBulkOperations() {
            this.bulkOperations.linkbuilder = null;
            this.bulkOperations.status = null;
            this.bulkOperations.niches = null;
            this.bulkOperations.projects = null;
            this.bulkOperations.used_emails = null;
            this.bulkOperations.remove = false;
        },
        hideFiltersDialog() {
            this.filtersDialog = false;
        },
        async importDonors() {
            const formData = new FormData();
            formData.append('file', this.importFile);
            console.log('file', formData);

            this.$store.commit('app/SHOW_LOADING');
            this.importDonorsDialog = false
            const response = await axiosIns.post('/api/donor-domains/import/csv', formData);

            if (response && response.status === 200) {
                this.$store.commit('app/SNACKBAR', {
                    show: true,
                    body: 'File imported successfully',
                    type: 'success',
                });
                this.loadDonors();
            } else {
                this.$store.commit('app/SNACKBAR', {
                    show: true,
                    body: 'Error happened, ensure that file is correct',
                    type: 'error',
                });
            }
            this.$store.commit('app/HIDE_LOADING');
        },
        resetFilters() {
            this.search = '';
            this.collaborateAtRange = [];
            this.showFilterCollaborateAtRange = false;
            this.wherePriceAN = null;
            this.whereDR = null;
            this.filterStatuses = [];
            this.filterLinkbuilders = [];
            this.filterProjects = [];
            this.filterNiches = [];
            this.hideFiltersDialog();
            this.loadDonors();
        },
        applyFilters() {
            this.hideFiltersDialog();
            this.loadDonors();
        },
        resetCollaborateAtRangeFilter() {
            this.collaborateAtRange = [];
            this.$refs.showFilterCollaborateAtRange.save(this.collaborateAtRange);
            this.showFilterCollaborateAtRange = false;
            // this.loadDonors();
        },
        setCollaborateAtRangeFilter() {
            this.$refs.showFilterCollaborateAtRange.save(this.collaborateAtRange);
            this.showFilterCollaborateAtRange = false;
            // this.loadDonors();
        },
        async exportCsv() {
            let url = `/api/donor-domains/export/csv?`;
            if (this.selectedRecords.length > 0) {
                let ids = [];
                this.selectedRecords.forEach((item) => {
                    ids.push(item.id);
                });
                url += 'selectedIds='+ids.join(',')+'&';
            }
            url += this.prepareFilterParamsForUrl();
            const response = await axiosIns.get(url);
            var a = document.createElement('a');
            var blob = new Blob([response.data], {'type': 'text/csv'});
            a.href = window.URL.createObjectURL(blob);
            a.download = 'donors.csv';
            a.click()
        },
      prepareFilterParamsForUrl() {
          let url = '';
          const statuses = Object.values(this.filterStatuses);
          const linkbuilders = Object.values(this.filterLinkbuilders);
          const projects = Object.values(this.filterProjects);
          const niches = Object.values(this.filterNiches);
          const competitors = Object.values(this.filterCompetitors);
          if (this.search.length > 2) {
              url += `&search=${this.search}`;
          }
          if (statuses.length > 0) {
              url += `&statuses=${statuses.join(',')}`;
          }
          if (linkbuilders.length > 0) {
              url += `&linkbuilders=${linkbuilders.join(',')}`;
          }
          if (projects.length > 0) {
              url += `&projects=${projects.join(',')}`;
          }
          if (niches.length > 0) {
              url += `&niches=${niches.join(',')}`;
          }
          if (competitors.length > 0) {
              url += `&competitors=${competitors.join(',')}`;
          }
          if (this.wherePriceAN && this.wherePriceAN.length > 1) {
              url += `&price_an=${this.wherePriceAN}`;
          }
          if (this.whereDR && this.whereDR.length > 1) {
              url += `&dr=${this.whereDR}`;
          }
          if (Object.values(this.collaborateAtRange).length > 1) {
              url += `&collaborate_between=${Object.values(this.collaborateAtRange).join(',')}`;
          }
          url += `&with_trashed=${this.withTrashed}`;
          return url;
      },
      async loadDonors() {
          this.$store.commit('app/SHOW_LOADING');
          let url = `/api/donor-domains?ipp=${this.options.itemsPerPage}&page=${this.options.page}`;
          url += this.prepareFilterParamsForUrl();
          const response = await axiosIns.get(url);
          if (response && response.status === 200) {
              this.donors = response.data.donorDomains.data;
              this.totalDonors = response.data.donorDomains.total;
          }
          this.$store.commit('app/HIDE_LOADING');
      },
        async loadCompetitors() {
          const response = await axiosIns.get('/api/competitor-domains');
          console.log('ihi');
          console.log(response.data.competitorDomains.data);
          this.competitors = response.data.competitorDomains.data;
        },
        async loadProjects() {
          const response = await axiosIns.get('/api/projects');
          this.projects = response.data.projects.data;
        },
        async loadNiches() {
          const response = await axiosIns.get('/api/niches');
          this.niches = response.data.niches.data;
        },
        async loadOurContacts() {
          const response = await axiosIns.get('/api/our-contacts');
          this.ourContacts = response.data.ourContacts.data;
        },
      evaluateContactColor(contact) {
          if (contact.status === 'new') {
              return 'orange';
          } else if (contact.status === 'active') {
              return 'green';
          } else if (contact.status === 'never_reply') {
              return 'red';
          } else if (contact.status === 'waiting_for_replay') {
              return 'blue';
          } else {
              return 'green';
          }
      },
      evaluateContactIcon(contact) {
          if (contact.type === 'email') {
              return 'fas fa-at';
          } else if (contact.type === 'twitter') {
              return 'fab fa-twitter';
          } else if (contact.type === 'linkedin') {
              return 'fab fa-linkedin';
          } else if (contact.type === 'contact_form') {
              return 'fas fa-desktop';
          } else {
              return 'fas fa-link';
          }
      },
      putContactIntoClipboard(contact) {
          navigator.clipboard.writeText(contact.value);
      },
      putEmailIntoClipboard(email) {
          navigator.clipboard.writeText(email);
      },
      evaluateStatusColor(status) {
          if (status === 'New') {
              return 'orange';
          } else if (status === 'Successful collaboration') {
              return 'green';
          } else if (status === 'Processing') {
              return 'amber';
          } else if (status === 'Expensive') {
              return 'purple';
          } else if (status === 'Didn\'t respond') {
              return 'blue';
          } else if (status === 'Filtered out') {
              return 'red';
          } else if (status === 'Outreach later') {
              return 'brown';
          } else {
              return 'red';
          }
      },
      evaluateStatusIcon(status) {
          // item.status == 'Successful collaboration' ? 'fas fa-check': 'fas fa-search'
          if (status === 'New') {
              return 'fas fa-search';
          } else if (status === 'Successful collaboration') {
              return 'fas fa-check';
          } else if (status === 'Processing') {
              return 'fas fa-spinner';
          } else if (status === 'Expensive') {
              return 'fas fa-dollar-sign';
          } else if (status === 'Didn\'t respond') {
              return 'fas fa-envelope';
          } else if (status === 'Filtered out') {
              return 'fas fa-trash-alt';
          } else if (status === 'Outreach later') {
              return 'fas fa-clock';
          } else {
              return 'fas fa-question';
          }
      },
      evaluateStatusBorderClass(status) {
          // item.status == 'Successful collaboration'
          //     ? 'border-success'
          //     : 'border-danger'
          if (status === 'new') {
              return 'border-default';
          } else if (status === 'Successful collaboration') {
              return 'border-success';
          } else {
              return 'border-danger';
          }
      },
      editItem(item) {
          this.$router.push('/donors/'+item.id);
      },
      changeSelectedHeaders(headers) {
            let headersWithCorrectOrder = [];
            this.headers.forEach((item) => {
                if (item.value === 'actions') {
                    return;
                }
                if (headers.includes(item.value)) {
                    headersWithCorrectOrder.push(item.value);
                }
            })
          axiosIns.post('/api/custom/entity-field/edit', {
              entityType: "User",
              entityId: this.$store.getters['user/profile'].id,
              field: "settings->donors->selectedHeaders",
              type: "json_part",
              value: Object.values(headersWithCorrectOrder)
          })
              .then((response) => {
                  // do nothing
              })
              .catch((error) => {
                  this.$store.commit('app/SNACKBAR', {
                      show: true,
                      body: error.response.data.message,
                      type: 'error',
                  });
              });
      },
        callModalEditNotes(item) {
            this.$store.commit('app/SHOW_EDIT_MODAL', {
                show: true,
                title: 'Edit notes',
                type: 'text-area',
                field: {
                    entityType: 'DonorDomain',
                    entityId: item.id,
                    type: 'string',
                    field: 'notes',
                    value: item.notes,
                },
                onSuccess: (entity) => {
                    item.notes = entity.notes;
                },
            });
        },
        callModalCreateContact(item) {
            this.$store.state.other.createContactModal.form.donor_domain_id = item.id;
            this.$store.state.other.createContactModal.onSuccess = (donorContact) => {
                item.contacts.push(donorContact);
            };
            this.$store.commit('other/SHOW_CONTACT_CREATE_MODAL');
        },
  }
};
</script>
<style>
button.v-btn.v-btn--active.v-btn--rounded.theme--dark.accent {
    background: black;
}
.v-data-table-header, .v-data-footer {
    background: lightgrey;
    color: black !important;
}
.v-data-table-header *, .v-data-footer * {
    color: black;
}
</style>
